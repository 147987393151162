/*
Flaticon icon font: Flaticon
Creation date: 27/03/2019 07:34
*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
  url("./Flaticon.woff2") format("woff2"),
  url("./Flaticon.woff") format("woff"),
  url("./Flaticon.ttf") format("truetype"),
  url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}


[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}



.flaticon-mobi̇lveaksesuar {
  overflow: hidden;
  background-image: url('./mobile_24.png');
  height: 24px;
  display: block;
  background-repeat: no-repeat;
  width: 25px;
  background-size: contain;
  /* clear: both; */
  position: relative;
  float: left;
}

.nav-item {
  .nav-link:hover {
    .flaticon-barkodürünler {
      background-image: url('./barcode_white.png');
    }
    .flaticon-mobi̇lveaksesuar {
      background-image: url('./mobile_white_24.png');
    }
  }
}


.flaticon-barkodürünler {
  //content: url("./barcode.png");
  overflow: hidden;
  background-image: url('./barcode.png');
  height: 24px;
  background-position: -5px 0;
  display: block;
  background-repeat: no-repeat;
  width: 25px;
  /* clear: both; */
  position: relative;
  float: left;

}

.flaticon-001-adapter:before {
  content: "\f100";
}

.flaticon-aksesuareki̇pman:before {
  content: "\f100";
}


.flaticon-002-antivirus:before, .flaticon-yazilim:before {
  content: "\f101";
}

.flaticon-003-bag:before {
  content: "\f102";
}

.flaticon-004-case:before, .flaticon-bi̇lgi̇sayar:before, .flaticon-KASA:before {
  content: "\f103";
  left: 6px;
  position: relative;
}

.flaticon-005-cd:before, .flaticon-tüketi̇mmalzemeleri̇:before {
  content: "\f104";
}

.flaticon-006-chair:before {
  content: "\f105";
}

.flaticon-007-cleaning:before {
  content: "\f106";
}

.flaticon-008-display:before, .flaticon-EKRANKARTI:before {
  content: "\f107";
}

.flaticon-009-dvi:before, .flaticon-çevi̇ri̇ci̇ler:before {
  content: "\f108";
}

.flaticon-010-earphones:before {
  content: "\f109";
}

.flaticon-011-extension:before {
  content: "\f10a";
}

.flaticon-012-fan:before, .flaticon-CPUFANI:before {
  content: "\f10b";
}

.flaticon-013-floppy-disk:before {
  content: "\f10c";
}

.flaticon-014-harddisk:before, .flaticon-SSDSATA:before {
  content: "\f10d";
}

.flaticon-015-harddisk-1:before, .flaticon-çevrebi̇ri̇mi̇:before {
  content: "\f10e";
}

.flaticon-016-gamepad:before, .flaticon-gamingürünler:before {
  content: "\f10f";
}

.flaticon-017-keyboard:before, .flaticon-KLAVYE:before, .flaticon-GAMINGKLAVYE:before, {
  content: "\f110";
}

.flaticon-018-lan:before {
  content: "\f111";
}

.flaticon-019-light-bulb:before {
  content: "\f112";
}

.flaticon-020-mainboard:before, .flaticon-ANAKART:before {
  content: "\f113";
}

.flaticon-021-microphone:before {
  content: "\f114";
}

.flaticon-022-mouse:before, .flaticon-GAMINGMOUSE:before, .flaticon-MOUSE:before, {
  content: "\f115";
}

.flaticon-023-power:before, .flaticon-POWERSUPPLY:before {
  content: "\f116";
}

.flaticon-024-power-1:before {
  content: "\f117";
}

.flaticon-025-printer:before, .flaticon-baskiçözümleri̇:before {
  content: "\f118";
}

.flaticon-026-cpu:before, .flaticon-bi̇lgi̇sayarparçalari:before, .flaticon-CPU:before {
  content: "\f119";
}

.flaticon-027-projector:before {
  content: "\f11a";
}

.flaticon-028-ram:before, .flaticon-BELLEK:before {
  content: "\f11b";
}

.flaticon-029-router:before, .flaticon-ağürünleri̇:before {
  content: "\f11c";
}

.flaticon-030-sd-card:before {
  content: "\f11d";
}

.flaticon-031-speaker:before, .flaticon-HOPARLÖR:before {
  content: "\f11e";
}

.flaticon-032-table:before, .flaticon-evofi̇selektroni̇ği̇:before {
  content: "\f11f";
}

.flaticon-033-tablet:before {
  content: "\f120";
}

.flaticon-034-usb:before, .flaticon-taşinabi̇li̇rdepolama:before {
  content: "\f121";
  left: 6px;
  position: relative;
}

.flaticon-035-usb-1:before, .flaticon-kablolar:before {
  content: "\f122";
}

.flaticon-036-webcam:before, .flaticon-güvenli̇kürünleri̇:before, .flaticon-WEBCAM:before {
  content: "\f123";
}

$font-Flaticon-001-adapter: "\f100";
$font-Flaticon-002-antivirus: "\f101";
$font-Flaticon-003-bag: "\f102";
$font-Flaticon-004-case: "\f103";
$font-Flaticon-005-cd: "\f104";
$font-Flaticon-006-chair: "\f105";
$font-Flaticon-007-cleaning: "\f106";
$font-Flaticon-008-display: "\f107";
$font-Flaticon-009-dvi: "\f108";
$font-Flaticon-010-earphones: "\f109";
$font-Flaticon-011-extension: "\f10a";
$font-Flaticon-012-fan: "\f10b";
$font-Flaticon-013-floppy-disk: "\f10c";
$font-Flaticon-014-harddisk: "\f10d";
$font-Flaticon-015-harddisk-1: "\f10e";
$font-Flaticon-016-gamepad: "\f10f";
$font-Flaticon-017-keyboard: "\f110";
$font-Flaticon-018-lan: "\f111";
$font-Flaticon-019-light-bulb: "\f112";
$font-Flaticon-020-mainboard: "\f113";
$font-Flaticon-021-microphone: "\f114";
$font-Flaticon-022-mouse: "\f115";
$font-Flaticon-023-power: "\f116";
$font-Flaticon-024-power-1: "\f117";
$font-Flaticon-025-printer: "\f118";
$font-Flaticon-026-cpu: "\f119";
$font-Flaticon-027-projector: "\f11a";
$font-Flaticon-028-ram: "\f11b";
$font-Flaticon-029-router: "\f11c";
$font-Flaticon-030-sd-card: "\f11d";
$font-Flaticon-031-speaker: "\f11e";
$font-Flaticon-032-table: "\f11f";
$font-Flaticon-033-tablet: "\f120";
$font-Flaticon-034-usb: "\f121";
$font-Flaticon-035-usb-1: "\f122";
$font-Flaticon-036-webcam: "\f123";
$font-Flaticon-037-mobile-accessories: "\f123";
