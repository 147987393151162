// Animation css
@import '~animate.css/animate.min.css';

// Bootstrap Layout scss
@import '~bootstrap/scss/bootstrap';

// Owl Carousel
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// Themify icon scss
@import 'themify';

// Theme Scss
@import 'theme/variables';

:root {
  --theme-deafult: #f45d25;
  --asbil-orange: #f45d25;
  --asbil-primary-text: #0f52ba; /*//#146986;*/
  --asbil-text: $asbil-text;
  --grey-color: $grey;
  --asbil-blackish: #131313;
  --asbil-blue: #0047bb; // #007bff; /*// #0f52ba; #00A4EF #156b88; // #1461f0 //*/
  --transition: .5s;
}

@import 'theme/style';
@import 'theme/theme-dark';
@import 'theme/menu';
@import 'theme/responsive';

// Toastr Css
@import '~ngx-toastr/toastr.css';

// Rating Scss
@import '~ngx-bar-rating/themes/br-default-theme';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800%7CPoppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@400;500;700;900&display=swap');

a {
  color: var(--asbil-text);
}

$poppins-style: 'Poppins', sans-serif;
