/* You can add global styles to this file, and also import other style files */
//@import '~@angular/cdk/overlay-prebuilt.css';
@import "./assets/scss/style";

/*"src/styles.scss",
"src/assets/scss/style.scss*/

gallery {
  background-color: transparent !important;
}

td {
  vertical-align: middle !important;
}

.card-body-custom {
  .cke_editable {
    margin-top: 1rem;
    padding: 1rem;
  }
}


figure.table {
  table {
    table-layout: fixed;
  }

  tbody {
    td:first-of-type {
      width: 15%;
    }
  }
}

.information-table,
.technical-table {
  p {
    color: #000000;
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;

    tbody {

      td:first-of-type, th:first-of-type {
        width: auto;
      }

      td:only-of-type {
        width: 80%;
      }

      tr {
        //background-color: #e0e0e0;
        border: 0;

        td, th {
          padding: 0.55rem;
          vertical-align: top;
          border-bottom: 5px solid #fff;
          border-right: 6.8px solid #fff;;
          background: #e8e8e8;
        }

        td:last-child {
          background: rgb(220 220 220 / 42%);
        }
      }
    }

  }

  ul {
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
      flex: 0 0 70%;
      border-right: 5px solid #fff;
      border-bottom: 5px solid #fff;
      display: flex;
      align-items: flex-end;
      padding: 0.75rem;
      background-color: #e0e0e0;
    }

    li:nth-child(odd) {
      flex: 0 0 30%;
    }
  }
}

.product-infinitescroll {
  margin: 50px 20px 0 20px;
}

div.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  //display: block;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: -65px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: #fff;
}

div.dropzone .dz-preview .dz-error-message::after {
  content: '';
  position: absolute;
  top: 70px;
  left: 64px;

  /* bottom: -46px; */
  transform: rotate(181deg);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

#tsparticles {
  position: absolute;
  width: 100%;
  // z-index: -1;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}


#loader, #loaderr {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 700s linear infinite;
  animation: spin 700ms linear infinite;
}

.loader-wrapper {
  z-index: 99999;
  width: 100%;
  height: 100vh;
  display: block;
  top: 0;
  background: #989494c4;
  overflow: hidden;
  position: fixed;
}

#loaderr {
  border-top: 16px solid #fd7e14;

}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0
  }
  to {
    bottom: 0px;
    opacity: 1
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

button.btn:disabled {
  cursor: not-allowed !important;
}

.order-modal {
  .modal-content {
    background-color: #f1f1f1;
  }
}

ckeditor .cke_textarea_inline, #cke_editor3 {
  min-height: 90vh
}

.md-drppicker.shown{
  display:flex;
}
.md-drppicker .ranges ul li {
  font-size: 12px;
  width: max-content;
}
