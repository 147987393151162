/* You can add global styles to this file, and also import other style files */
@import 'assets/fonts/menu-icon-font/flaticon';
@import '~@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'semantic-ui-label/label.css';
.cursor-pointer {
	cursor: pointer;
}


.asbil-form-control{
	margin-bottom: 0.243rem;
}

.mySwiper-thumb {
	height: 20%;
	box-sizing: border-box;
	padding: 10px 0;
	cursor: pointer;
}

.mySwiper-thumb .swiper-slide {
	width: 25%;
	height: 100%;
	opacity: 0.4;
}

.mySwiper-thumb .swiper-slide-thumb-active {
	opacity: 1;
}
.mySwiper-thumb .swiper-button-prev:after, .swiper-button-next:after{
	font-size:15px;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.text-underline {
	text-decoration: underline;
}

.home-slider {
	.owl-prev,
	.owl-next {
		background: transparent !important;
		border: 1px solid white;
	}
}

.loader-wrapper {
	height: 100vh;
	width: 100%;
	//position: absolute;
	z-index: 99999999;
	background: rgb(247 247 247 / 67%);
}

.lds-facebook {
	display: inline-block;
	width: 80px;
	height: 80px;
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 99;
}

.lds-facebook div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #d21717;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
	left: 56px;
	animation-delay: 1ms;
}

@keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

.paynetj-button {
	background-color: var(--asbil-blue);

	text-align: center;
	transition: 0.3s ease-in-out;
	display: block;
	width: 100%;
	color: #fff;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	font-weight: 400;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	border-color: var(--asbil-blue);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
}

gallery {
	background-color: transparent !important;
}

body {
	font-family: 'Roboto', sans-serif;
}

@media print {
	body * {
		visibility: hidden;
	}
	#printBuildTable,
	#printBuildTable * {
		visibility: visible;
	}
	#printBuildTable {
		position: absolute;
		left: 0;
		top: 0;
	}
}

@-ms-viewport {
	///
}

// Responsive menu css start //
@media (min-width: 1200px) {
}

html {
	scroll-behavior: smooth;
}

.collection-product-wrapper .product-top-filter {
	margin-bottom: 10px;
}

.modal-content {
	.collection-product-wrapper .product-top-filter {
		background: var(--asbil-blue);
	}
}

$asbil-text: #1a2132;
$poppins-style: 'Poppins', sans-serif;
.section-product {
	.product-box2 {
		img {
			width: 90%;
		}

		.media-body {
			padding-right: 10px;
		}
	}
}

.owl-dots {
	position: absolute;
	bottom: 1rem;
	width: 100%;
}

.product-wrapper-grid .list-view-inner,
.related-products .list-view-inner,
.new-products-slider .list-view-inner {
	display: none;
}

.new-products-slider {
	.owl-stage {
		padding-top: 1rem !important;
	}

	.owl-next,
	.owl-prev {
		background: #f45d25 !important;
		height: 30px;
		width: 30px;
		font-size: 15px !important;
		position: absolute;
		top: -40px;
		right: 0;
		padding: 7px 7px !important;
	}

	.owl-prev {
		right: 45px;
	}

	.img-wrapper {
		//margin: 0!important;
		//border: 1px solid #e0e0e0
	}

	.product-box {
		border: 1px solid #e0e0e0;

		margin: 2px;
		border-radius: 1px;

		.front {
			border: unset;

			&:hover {
				//transform: scale(1	);
			}
		}

		&:hover {
			.product-inner-wrapper {
				box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
			}
		}

		.product-detail {
			margin: 0;
			padding: 0 10px 10px 10px;
			border-top: unset;
			border-right: unset !important;
			border-left: unset !important;
			border-bottom: unset !important;

			.btn {
				font-size: 13px;
				line-height: 2;
			}
		}
	}
}

.related-products {
	.btn {
		font-size: 12px;
	}
}

.product-wrapper-grid.list-view .list-view-inner {
	display: flex;
}

.product-box .product-detail h4 {
	font-size: 15px;
}

.product-wrapper-grid.list-view .grid-view-content {
	display: none;
}

.product-wrapper-grid.list-view .product-box {
	width: 100%;
}

.product-wrapper-grid.list-view .product-inner-wrapper {
	display: flex;
	width: 100%;
	//border: 0 solid #d8d8d8;
	//border-bottom-width: 1px;
	padding: 5px !important;
}

.product-wrapper-grid.list-view .product-box .product-detail {
	width: 100%;
	border: none;
	/* margin: 18px; */
}

.collection-product-wrapper .product-wrapper-grid.list-view .img-wrapper {
	float: left;
	width: 3.8%;
}

div.collection-product-wrapper .product-wrapper-grid .product-box {
	margin-top: 0;
	border: 1px solid #e0e0e0;
	border-radius: 10px;
}

.grid-view .col-grid-box {
	margin-bottom: 1rem;

	.product-box .img-wrapper .front,
	.product-wrap .img-wrapper .front {
		padding: 2px;
		background: white;
	}
}

.list-view .col-grid-box {
	margin-bottom: 5px;
}

.collection-product-wrapper {
	.stock {
		padding: 0 0 0 0;
	}
}

div.product-box .product-detail h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-size: 13px;
	line-height: 1.4;
	min-height: 60px;
	max-height: 115px;
	color: #0e0e0e;
	text-overflow: ellipsis;
	overflow: hidden;
}

.picture-modal .modal-dialog {
	max-width: 55vw;
	height: 75vh;
	display: block;
	position: relative;
	background-color: greenyellow;

	.modal-content {
		height: 100%;
		max-height: 100%;
		display: block;
		max-width: 100%;
		overflow: hidden;
		position: relative;

		.product-slick.big-pic .owl-item {
			//width: 50px!important;/
		}
	}
}

.picture-modal {
	* {
		box-sizing: border-box;
	}

	.row > .column {
		padding: 0 8px;
	}

	.row:after {
		content: '';
		display: table;
		clear: both;
	}

	.column {
		/* loat: left;*/
		width: 12%;
		max-width: 10vw;
		max-height: 20vh;
		display: inline-grid;
		margin: auto;
		position: relative;
		/* float: left; */
		padding: 5px;
	}

	/* The Modal (background) */
	.modal {
		display: none;
		position: fixed;
		z-index: 1;
		padding-top: 100px;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: black;
	}

	/* Modal Content */
	.modal-content {
		position: relative;
		background-color: #fefefe;
		margin: auto;
		padding: 0;
		width: 100%;
		max-width: 1200px;
	}

	/* The Close Button */
	.close {
		color: white;
		position: absolute;
		top: 10px;
		right: 25px;
		font-size: 35px;
		font-weight: bold;
	}

	.close:hover,
	.close:focus {
		color: #999;
		text-decoration: none;
		cursor: pointer;
	}

	.mySlides {
		// display: none;

		height: 80vh;
		position: relative;
		clear: both;
		text-align: center;
		width: 55%;
		margin: auto;

		img {
			max-height: 75vh;
		}
	}

	.cursor {
		cursor: pointer;
	}

	/* Next & previous buttons */
	.prev,
	.next {
		cursor: pointer;
		position: absolute;
		top: 50%;
		width: auto;
		padding: 16px;
		margin-top: -50px;
		color: white;
		font-weight: bold;
		font-size: 20px;
		transition: 0.6s ease;
		border-radius: 0 3px 3px 0;
		user-select: none;
		-webkit-user-select: none;
	}

	/* Position the "next button" to the right */
	.next {
		right: 0;
		border-radius: 3px 0 0 3px;
	}

	/* On hover, add a black background color with a little bit see-through */
	.prev:hover,
	.next:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}

	/* Number text (1/3 etc) */
	.numbertext {
		color: #f2f2f2;
		font-size: 12px;
		padding: 8px 12px;
		position: absolute;
		top: 0;
	}

	img {
		margin-bottom: -4px;
	}

	.demo {
		opacity: 0.6;
	}

	.active,
	.demo:hover {
		opacity: 1;
	}

	img.hover-shadow {
		transition: 0.3s;
	}

	.hover-shadow:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
}

app-product-box-one {
	width: 100%;
}

div.product-box.product-collections {
	.product-detail .grid-view-content {
		padding: 0 10px 10px;
	}
}

.section-collection .product-box2 {
	.front img {
		width: 60%;
		margin: auto;
	}

	.front {
		a {
			display: grid;
		}
	}

	.product-detail {
		text-align: center;

		h6 {
			font-weight: 900;
		}
	}
}

.product-box {
	.front {
		//border: 1px solid #e0e0e0;
		border-bottom: none;
	}
}

h6.product-name a {
	color: var(--asbil-blackish);

	/* word-break: break-all; */
	text-overflow: ellipsis;
	/* overflow: hidden; */
	height: auto;
	display: block;
}

.btn-primary {
	background-color: var(--asbil-blue);
	border-color: var(--asbil-blue); //#258ebf;

	&:hover {
		background-color: white;
		border-color: var(--asbil-blue);
		color: var(--asbil-blue);
	}
}

// Gaming page styles
svg.gamingSvgBg {
	visibility: hidden;
	position: absolute;
}

body.gaming_theme {
	background-size: cover;
	//background: linear-gradient(rgba(4, 3, 3, 0.72), rgba(4, 3, 3, 0.72)),
	//url('././assets/images/honeyComb-bg.svg');
	//url('././assets/images/icon/gaming-icons/full-bg.jpg') no-repeat fixed;
	background-position: center center;

	.product-filter-tags li a.filter_tag {
		color: #fff;
		border: unset;
	}

	.clear_filter {
		color: #fff !important;
	}

	header,
	.breadcrumb-section {
		background-color: transparent !important;
	}

	svg.gamingSvgBg {
		position: fixed;
		visibility: visible;
	}

	.btn.pc-toplama {
		border: 0.1rem solid white;
		cursor: pointer;
		line-height: 1.5;
		padding: 1em 2em;
		letter-spacing: 0.05rem;
	}

	.btn.pc-toplama:focus {
		outline: 2px dotted var(--asbil-blackish);
	}

	.pc-toplama {
		transition: color 0.25s 0.0833333333s;
		position: relative;
		background: rgb(19 19 19 / 2%);
		color: #fff;
	}

	.pc-toplama::before,
	.pc-toplama::after {
		border: 0 solid transparent;
		box-sizing: border-box;
		content: '';
		pointer-events: none;
		position: absolute;
		width: 0;
		height: 0;
		bottom: 0;
		right: 0;
	}

	.pc-toplama::before {
		border-bottom-width: 2px;
		border-left-width: 2px;
	}

	.pc-toplama::after {
		border-top-width: 2px;
		border-right-width: 2px;
	}

	.pc-toplama:hover {
		color: #fff;
		border: unset;
	}

	//.pc-toplama:hover::before, .pc-toplama:hover::after {
	//  border-color: #f45d25;
	//  transition: border-color 0s, width 0.25s, height 0.25s;
	//  width: 100%;
	//  height: 100%;
	//  //content: 'PC TOPLAMA';
	//  //
	//  //
	//  //padding-top: 5px;
	//}

	.pc-toplama:hover::before {
		transition-delay: 0s, 0s, 0.25s;
	}

	.pc-toplama:hover::after {
		transition-delay: 0s, 0.25s, 0s;
	}

	.retailer-account a,
	.welcome-note span {
		color: #eee !important;
	}

	.menu-container {
		background: transparent !important;
	}

	footer .custom-footer {
		//background: rgba(4, 3, 3, 0.72) !important;
	}

	.breadcrumb-section {
		//background-color: rgb(199 191 191 / 22%);
		.breadcrumb a {
			color: #ffffff;
			font-weight: 600;
		}

		.breadcrumb-item.active {
			color: #ffffff;
		}

		.page-title h2 {
			color: white;
			font-weight: 700;
		}
	}

	.collection-collapse-block .collapse-block-title {
		position: relative;
		cursor: pointer;
		text-transform: uppercase;
		font-size: 16px;
		color: #444444;
		font-weight: 800;
		margin: 30px 0 0 0;
	}

	.collection-collapse-block-content
		.collection-brand-filter
		.category-list
		li
		a {
		color: #000000;
		padding-bottom: 10px;
	}

	.collection-filter-block {
		background-color: rgb(255 255 255 / 97%); //rgb(255 255 255 / 60%);

		.collection-collapse-block-content .collection-filter-checkbox label {
			color: #000000;
			font-weight: 900;
		}
	}

	.product-top-filter,
	.fixMe {
		background-color: white; //rgb(255 255 255 / 60%);

		h5 {
			color: #000000;
		}
	}

	.product-wrapper-grid.list-view .product-inner-wrapper {
		padding-bottom: 0;
		background: white;
	}

	.product-detail {
		background: white;
	}

	.product-pagination {
		background-color: rgb(255 255 255 / 60%);

		.product-search-count-bottom h5 {
			color: #000000;
		}
	}

	.collection-product-wrapper {
		background: rgb(247 247 247 / 10%) !important;
		padding: 5px;
	}

	.grid-wrapper-inner li {
		color: #343a40 !important;
	}

	.grid-wrapper-inner li.active {
		color: #f45d25 !important;
	}
}

.gaming-product-slider {
	div.owl-carousel .owl-stage {
		display: flex;
	}

	div.owl-carousel .owl-item {
		width: 179px !important;
	}
}

.grid-view {
	.product-inner-wrapper {
		min-height: 100%;
		transition: scale 8s;
		transform: scale(1);
	}

	.product-inner-wrapper:hover {
		box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
		transform: scale(0.9999);
		z-index: 1;
		display: block;
		clear: both;
		position: relative;
		background-color: white;

		img {
			transform: scale(0.89);
		}
	}
}

.information-table,
.technical-table {
	p {
		color: #000000;
		padding-bottom: 10px;
	}

	table {
		width: 100%;
		margin-bottom: 1rem;
		color: #212529;

		tbody {
			td:first-of-type,
			th:first-of-type {
				width: auto;
			}

			td:only-of-type {
				width: 80%;
			}

			tr {
				//background-color: #e0e0e0;
				border: 0;

				td,
				th {
					//padding: 0.55rem;
					//vertical-align: top;
					//border-bottom: 5px solid #fff;
					//border-right: 6.8px solid #fff;
					//background: #e8e8e8;
				}

				td:last-child {
					//background: rgb(220 220 220 / 42%);
				}
			}
		}
	}

	ul {
		list-style-type: none;
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		li {
			flex: 0 0 70%;
			border-right: 5px solid #fff;
			border-bottom: 5px solid #fff;
			display: flex;
			align-items: flex-end;
			padding: 0.75rem;
			background-color: #e0e0e0;
		}

		li:nth-child(odd) {
			flex: 0 0 30%;
		}
	}
}

.collection-product-wrapper
	.product-top-filter.fixMe
	.product-filter-content
	.collection-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view {
	padding: 10px;
}

.product-page-filter ul {
	margin-right: 1rem;
}

.breadcrumb-section {
	padding: 0px 0;

	.page-title h2 {
		font-size: 14px;
	}
}

.product-page-filter {
	ul.markar-filter {
		border-color: #ff3f3f;
	}
}

.new-products-slider {
	.owl-carousel.owl-drag .owl-item {
		max-width: 255px !important;
	}
}

.main-menu .sub-menu-card {
	//background: #2289a7; //var(--theme-deafult);
}

.category-active {
	color: var(--theme-deafult) !important;
}

.breadcrumb-section .breadcrumb {
	justify-content: start;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
	background-color: white;
}

.product-detail-page {
	.slider-nav .owl-stage {
		height: 115px;
	}
}

.slider-nav.custom-width .product-slick {
	.owl-stage .owl-item {
		width: 115px !important;
	}
}

gallery-core[imageSize='contain'] gallery-slider .g-image-item {
	//background-size: auto !important;
	width: 30%;
	height: 650px;
}

lightbox gallery {
	height: 95% !important;
}

.g-image-item {
	background-size: contain !important;
}

.g-btn-close svg,
gallery-nav svg {
	opacity: 1.6 !important;

	path {
		fill: red;
	}
}

.g-btn-close {
	right: 3.9em !important;
	top: 1.9em !important;
}

p[data-f-id='pbf'] {
	display: none !important;
}

figure.table {
	table {
		table-layout: fixed;
	}

	tbody {
		td:first-of-type {
			width: 15%;
		}
	}
}

.technical,
#technical-tab-panel,
.tech-tab {
	.table-responsive {
		table {
			//table-layout: fixed;
		}

		tbody {
			td:first-of-type {
				width: 20% !important;
			}
		}
	}

	td:first-child {
		width: 20% !important;
		font-weight: bold;
	}
}

#technical-tab-panel {
	.fr-view table th {
		background: unset !important;
	}

	.fr-view table tr:nth-child(even) {
		//background: #e0e0e0!important;
		td {
			//background: #e0e0e0!important;
		}
	}
}

.form_search {
	.dropdown-menu {
		//display: none;
		button {
			position: relative;

			img {
				height: 50%;
				width: 50px;
			}
		}

		.dropdown-item.active,
		.dropdown-item:active {
			color: #fff;
			text-decoration: none;
			background-color: #f45d25;
		}

		width: 100%;
		background: #f5f5f5;
		max-height: 65vh;
		overflow-y: scroll;
		box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
			rgb(0 0 0 / 30%) 0px 3px 7px -3px;
		transition: all ease-in;

		ul {
			display: grid;

			li {
				//float: left;
				text-align: left;
				padding: 8px;
				transition: background-color 0.5s;
				display: flex;
				position: relative;
				align-items: center;

				&:hover {
					//cursor: pointer;
					background-color: white;
				}

				img {
					height: 50%;
					width: 50px;
				}

				img:hover,
				.title:hover {
					cursor: pointer;
					color: var(--asbil-primary-text) !important;
				}
			}
		}
	}
}

.pc_builder_form_search {
	.dropdown-menu {
		//display: none;
		button {
			position: relative;

			img {
				height: 50%;
				width: 50px;
			}
		}

		.dropdown-item.active,
		.dropdown-item:active {
			color: #fff;
			text-decoration: none;
			background-color: #f45d25;
		}

		width: auto;
		background: #f5f5f5;
		max-height: 65vh;
		overflow-y: scroll;
		box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
			rgb(0 0 0 / 30%) 0px 3px 7px -3px;
		transition: all ease-in;

		ul {
			display: grid;

			li {
				//float: left;
				text-align: left;
				padding: 8px;
				transition: background-color 0.5s;
				display: flex;
				position: relative;
				align-items: center;

				&:hover {
					//cursor: pointer;
					background-color: white;
				}

				img {
					height: 50%;
					width: 50px;
				}

				img:hover,
				.title:hover {
					cursor: pointer;
					color: var(--asbil-primary-text) !important;
				}
			}
		}
	}
}

.productModalClass .modal-dialog {
	max-width: calc(100vw - 100px);
}

.custom-build {
	//background-color: aliceblue;
}

app-product-modal {
	background-color: rgb(226 226 226 / 55%);

	.product-box,
	.product-detail {
		background: #f7f7f7;
	}
}

.accordion > .card {
	border: #ff580a 1.9px solid;

	button.btn.btn-link.collapsed {
		font-size: 1.3em;
		text-decoration: none;
		color: #4a6165;
	}

	.opened {
		background-color: #ca500f;
	}

	button.btn.btn-link {
		text-decoration: none;
		color: var(--theme-deafult);
	}
}

.card.product-card {
	transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
		0.3s box-shadow,
		0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
	//cursor: pointer;

	a:hover {
		color: var(--theme-deafult);
	}

	img:hover {
		cursor: pointer;
	}
}

.card.product-card:hover {
	//transform: scale(1.05);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
	z-index: 1;

	.product-name {
		max-height: unset; /* fallback */
		-webkit-line-clamp: inherit; /* number of lines to show */
		-webkit-box-orient: vertical;
	}
}

.price {
	font-weight: 600;
	margin: 0;
	font-size: 15px;
}

.btn-primary.disabled,
.btn-primary:disabled {
	color: whitesmoke;
	background-color: #6c757d;
	border-color: #6c757d;
	cursor: not-allowed;
}

.cart-modal {
	.modal-content {
		min-height: 40vh;
	}
}

.district-type-ahead {
	ngb-typeahead-window#ngb-typeahead-2 {
		max-height: 200px;
		overflow: scroll;
		overflow-x: hidden;
		width: 100%;
	}
}

.overlay {
	margin: auto;
	display: flex;
	position: fixed; /* Sit on top of the page content */
	width: 100%; /* Full width (cover the whole page) */
	height: 100%; /* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
	z-index: 999; /* Specify a stack order in case you're using a different order for other elements */
	cursor: not-allowed; /* Add a pointer on hover */
}

.overlay .loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 100px;
	height: 100px;
	margin: auto;
	animation: spin 2s linear infinite;
}

.owl-stage {
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.mainLoader {
	margin: 0;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	width: 100%;
	z-index: 999999;
	background: aliceblue;

	svg text {
		letter-spacing: 10px;
		stroke: var(--asbil-orange);
		font-size: 150px;
		font-weight: 700;
		stroke-width: 3;

		animation: textAnimate 5s infinite alternate;
	}

	@keyframes textAnimate {
		0% {
			stroke-dasharray: 0 50%;
			stroke-dashoffset: 20%;
			fill: hsl(217deg 100% 37%); // hsl(211deg 100% 50%)
		}

		100% {
			stroke-dasharray: 50% 0;
			stroke-dashoffstet: -20%;
			fill: hsla(189, 68%, 75%, 0%);
		}
	}
}

/* width */
::-webkit-scrollbar {
	width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px snow;
	border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: darkgrey;
	border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--asbil-blue);
}

.modal-dialog {
	.collection-filter {
		overflow-y: scroll;
		position: fixed;
		height: 77vh;
		z-index: -45;
		width: 285px;
	}

	.collection-filter-block {
		margin-bottom: 30px;
		padding: 0 30px;
		border: 1px solid #f45d25;
		background-color: #ffffff;
		border-radius: 5px;
		position: absolute;
		/* overflow: scroll; */
		/* display: inline; */
		width: 100%;
	}

	.collection-wrapper .col-lg-3 {
		position: sticky;
	}

	.collection-filter::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px snow;
		border-radius: 7px;
	}

	.collection-filter::-webkit-scrollbar {
		width: 8px;
	}

	.collapse-block-title {
		font-size: 12px;
	}
}

.sub-menu-card {
	// linear-gradient(rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.23)),
	background-image: url('assets/images/17973908.jpg');
}

.productModalClass {
	.modal-content {
		min-height: 95vh;
	}

	app-product-modal {
		min-height: inherit !important;
	}
}

del {
	font-size: 12px;
	font-weight: 400;
	color: var(--theme-deafult);
}

.asbil-color {
	color: var(--theme-deafult) !important;
}

.grid {
	display: grid;
}

.lable-block {
	position: absolute;
	left: 20px;
	align-content: flex-start;
	align-items: center;
	display: flex;
	top: 5px;
	/* height: 9rem; */
}

.product-tag.discount-tag {
	position: relative;
	padding-left: 1.5em;
	padding-right: 1.5em;
	border-radius: 0 0.28571429rem 0.28571429rem 0;
	transition: none;
	/* margin-top: 49px; */
	left: 0px;
	display: flex;
	color: #f45d25;
	font-size: 10px;
}

.product-tag.discount-tag::before {
	position: absolute;
	-webkit-transform: translateY(-50%) translateX(50%) rotate(-45deg);
	transform: translateY(-50%) translateX(50%) rotate(-45deg);
	top: 50%;
	right: 100%;
	content: '';
	background-color: inherit;
	background-image: none;
	width: 1.56em;
	height: 1.56em;
	-webkit-transition: none;
	transition: none;
}
.product-tag.discount-tag:after {
	position: absolute;
	content: '';
	top: 50%;
	left: -0.25em;
	margin-top: -0.25em;
	background-color: #fff !important;
	width: 0.5em;
	height: 0.5em;
	-webkit-box-shadow: 0 -1px 1px 0 rgb(0 0 0 / 30%);
	box-shadow: 0 -1px 1px 0 rgb(0 0 0 / 30%);
	border-radius: 500rem;
}

.product-tag {
	display: inline-block;
	line-height: 1;
	vertical-align: baseline;
	margin: 0 0.14285714em;
	background-color: #e8e8e8;
	background-image: none;
	padding: 0.5833em 0.833em;
	color: rgba(0, 0, 0, 0.6);
	text-transform: none;
	font-weight: 700;
	border: 0 solid transparent;
	border-radius: 0.28571429rem;
	-webkit-transition: background 0.1s ease;
	transition: background 0.1s ease;
	margin-left: -5px;
}

.product-image-wrapper:hover {
	.product-tag {
		// transform: rotate(90deg);
	}
}

.btn {
	border-radius: 7px !important;
}
app-product-box-one:hover {
	.asbil-card {
		box-shadow: 0 10px 20px rgb(0 0 0 / 12%), 0 4px 8px rgb(0 0 0 / 6%);
		z-index: 1;
	}
}

div.collection-filter-block {
	border-radius: 12px !important;
}

.main-menu .sub-menu-card {
	border: 1px solid rgba(244, 92, 37, 0.712);
	border-radius: 0 12px 12px 0;
}
.parent-items {
	border-radius: 12px 0 0 12px !important;
}

.product-filter-tags {
	display: flex;
	/* flex: 1; */
	/* max-width: 100px; */
	flex-wrap: wrap;
}
